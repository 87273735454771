import React from 'react'

import { useTranslations } from 'next-intl'
import Image from 'next/image'

const Partner = () => {
  const t = useTranslations()

  const images = [
    '/images/logo-perusahaan/abc-heinz.jpeg',
    // '/images/logo-perusahaan/adf.jpg',
    // '/images/logo-perusahaan/aloz.jpeg',
    // '/images/logo-perusahaan/angkasa-pura-ii.jpeg',
    // '/images/logo-perusahaan/art-piston.jpeg',
    // '/images/logo-perusahaan/asian-paints.jpeg',
    '/images/logo-perusahaan/auto-2000.png',
    '/images/logo-perusahaan/befa.jpeg',
    // '/images/logo-perusahaan/bentoel-group.jpg',
    // '/images/logo-perusahaan/braun.jpeg',
    // '/images/logo-perusahaan/bukaka.jpeg',
    // '/images/logo-perusahaan/chingluh.jpeg',
    '/images/logo-perusahaan/daihatsu.png',
    // '/images/logo-perusahaan/daiwa-house.jpeg',
    '/images/logo-perusahaan/dhl.jpeg',
    '/images/logo-perusahaan/djabesmen.jpeg',
    // '/images/logo-perusahaan/eagle-nice.jpeg',
    '/images/logo-perusahaan/Firmenich.jpg',
    // '/images/logo-perusahaan/fuji-seat.jpg',
    // '/images/logo-perusahaan/Fukoku.jpg',
    '/images/logo-perusahaan/giic.jpeg',
    '/images/logo-perusahaan/glaze.jpeg',
    '/images/logo-perusahaan/Glico Wings.jpg',
    '/images/logo-perusahaan/goodyear.jpeg',
    '/images/logo-perusahaan/green-cold.jpeg',
    '/images/logo-perusahaan/GS Battery.jpg',
    '/images/logo-perusahaan/gs.png',
    // '/images/logo-perusahaan/Gunung Garuda.jpg',
    '/images/logo-perusahaan/Haier.jpg',
    '/images/logo-perusahaan/Hitachi.jpg',
    '/images/logo-perusahaan/Honda.jpg',
    '/images/logo-perusahaan/hrs.jpeg',
    // '/images/logo-perusahaan/hsi.jpeg',
    '/images/logo-perusahaan/Hyundai.jpg',
    '/images/logo-perusahaan/Idemitsu.jpg',
    // '/images/logo-perusahaan/indofood.jpeg',
    '/images/logo-perusahaan/iwatani.jpeg',
    '/images/logo-perusahaan/jababeka.jpeg',
    '/images/logo-perusahaan/jtekt.jpeg',
    '/images/logo-perusahaan/JVC Red JPG.jpg',
    // '/images/logo-perusahaan/jx.jpeg',
    '/images/logo-perusahaan/Kalbe.jpg',
    '/images/logo-perusahaan/kansai-paint.jpeg',
    '/images/logo-perusahaan/kao.png',
    '/images/logo-perusahaan/kiic.jpeg',
    '/images/logo-perusahaan/komatsu.jpeg',
    '/images/logo-perusahaan/kyb.jpeg',
    // '/images/logo-perusahaan/League.jpg',
    // '/images/logo-perusahaan/lemindo.jpeg',
    // '/images/logo-perusahaan/lg.png',
    // '/images/logo-perusahaan/lippo-group.jpeg',
    // '/images/logo-perusahaan/Loreal Indonesia.jpg',
    // '/images/logo-perusahaan/Martha Tilaar.jpg',
    '/images/logo-perusahaan/mattel.jpeg',
    '/images/logo-perusahaan/maxxis.png',
    '/images/logo-perusahaan/meidoh.jpeg',
    // '/images/logo-perusahaan/mengniu.jpeg',
    // '/images/logo-perusahaan/Mercedes Benz.jpg',
    '/images/logo-perusahaan/mitsubishi logo.jpg',
    '/images/logo-perusahaan/namicoh.png',
    // '/images/logo-perusahaan/nipro.jpeg',
    // '/images/logo-perusahaan/panarub.jpeg',
    // '/images/logo-perusahaan/Parkland.jpg',
    // '/images/logo-perusahaan/PCD.jpg',
    // '/images/logo-perusahaan/Popkhand.jpg',
    // '/images/logo-perusahaan/PT Anugerah Mortar.jpg',
    // '/images/logo-perusahaan/PT Garuda Metalindo.jpg',
    // '/images/logo-perusahaan/PT Indocement.jpg',
    // '/images/logo-perusahaan/PT Kino.jpg',
    // '/images/logo-perusahaan/PT Martina Berto.jpg',
    // '/images/logo-perusahaan/PT Nestle.jpg',
    // '/images/logo-perusahaan/PT Yoska Prima.jpg',
    '/images/logo-perusahaan/rs-hermina.jpeg',
    '/images/logo-perusahaan/Samsung.jpeg',
    '/images/logo-perusahaan/Sekolah Pilar Indonesia.jpg',
    '/images/logo-perusahaan/sharp.jpeg',
    '/images/logo-perusahaan/skyworth.jpeg',
    '/images/logo-perusahaan/Smart Solutions.jpg',
    // '/images/logo-perusahaan/Sojitz.jpeg',
    // '/images/logo-perusahaan/surya-cipta.jpeg',
    '/images/logo-perusahaan/Suzuki-logo.jpg',
    '/images/logo-perusahaan/Tata Logam.jpg',
    '/images/logo-perusahaan/tef.jpeg',
    '/images/logo-perusahaan/thai-summit-group.jpeg',
    '/images/logo-perusahaan/toyota.jpg',
    '/images/logo-perusahaan/Unicharm.jpg',
    '/images/logo-perusahaan/velasto.jpeg',
    // '/images/logo-perusahaan/vivo.jpeg',
    '/images/logo-perusahaan/wika.jpeg',
    '/images/logo-perusahaan/Wuling.jpg',
    // '/images/logo-perusahaan/xacti.jpeg',
    '/images/logo-perusahaan/Yamazaki.jpg',
    '/images/logo-perusahaan/yanmar.jpeg',
    // '/images/logo-perusahaan/Yazaki.jpg',
    '/images/logo-perusahaan/yofc.jpeg',
    // '/images/logo-perusahaan/yoska.jpeg',
  ]

  return (
    <section className="mx-auto my-20 w-full space-y-10 px-6 lg:max-w-[970px]">
      <header className="space-y-2 text-center lg:space-y-3">
        <p className="text-2xl font-medium lg:text-3xl">
          {t('OurPartners.title-1')}
        </p>
        <h1 className="text-5xl font-medium lg:text-6xl">
          {t('OurPartners.title-2')}
        </h1>
        <p className="text-sm font-normal lg:text-lg">
          {t('OurPartners.content')}
        </p>
      </header>
      {/* <div className="text-center">
        <button className="rounded-2xl bg-primary-1 px-10 py-3 text-lg font-bold text-white lg:text-2xl">
          Contact Us
        </button>
      </div> */}
      <div className="relative flex flex-wrap items-center justify-center gap-4 lg:gap-8">
        {images.map((item, index) => (
          <div key={index} className="h-10 w-20 md:h-24 md:w-36">
            <Image
              src={item}
              className="h-full w-full object-contain"
              alt="Partner Logo"
              width={0}
              height={0}
              sizes="100vw"
            />
          </div>
        ))}
        <div className="absolute bottom-0 left-0 h-[350px] w-full bg-gradient-to-t from-white"></div>
      </div>
    </section>
  )
}

export default Partner
