import React from 'react'

const Hero = () => {
  return (
    <div className="relative mx-auto mt-8 h-[70vh] w-full max-w-[1600px] sm:h-[80vh] md:h-[90vh] lg:h-[calc(100vh-64px-12px)]">
      <video
        src="/videos/Advertising PIMA.mp4"
        autoPlay
        loop
        muted
        playsInline
        className="h-full w-full rounded-[60px] object-cover shadow-2xl sm:h-[80vh] md:h-[90vh] lg:h-[calc(100vh-64px-12px)]"
      />
      <div className="absolute inset-0 rounded-[60px] bg-black opacity-50"></div>
      <div className="absolute left-1/2 top-1/2 z-10 -translate-x-1/2 -translate-y-1/2 transform text-center">
        <h1 className="mb-4 text-5xl font-medium tracking-widest text-white lg:text-7xl">
          Smart, Easy <br /> and Efficient
        </h1>
      </div>
    </div>
  )
}

export default Hero
