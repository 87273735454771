import { FaChevronLeft, FaChevronRight } from 'react-icons/fa6'
import { GrLocation } from 'react-icons/gr'

import { useTranslations } from 'next-intl'
import Image from 'next/image'
import { EffectCoverflow, Navigation, Pagination } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'

import { OUTLET_LOCATION } from '@/libs/data'

const OutletLocation = () => {
  const t = useTranslations()

  return (
    <div
      id="ConvenienceStore"
      className="mt-20 flex flex-col items-center gap-5 lg:mt-28 lg:gap-10"
    >
      <h1 className="px-5 text-center text-2xl font-bold lg:max-w-[550px] lg:text-5xl lg:font-medium">
        {t('OutletLabel')}
      </h1>

      <Swiper
        effect={'coverflow'}
        grabCursor={false}
        centeredSlides={true}
        loop={true}
        slidesPerView={'auto'}
        coverflowEffect={{
          rotate: 0,
          stretch: 0,
          depth: 100,
          modifier: 2.5,
          slideShadows: true,
        }}
        pagination={{
          type: 'fraction',
          el: '.swiper-pagination',
        }}
        navigation={{
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        }}
        modules={[EffectCoverflow, Pagination, Navigation]}
      >
        {OUTLET_LOCATION.map((item) => (
          <SwiperSlide className="space-y-5 lg:space-y-10" key={item.id}>
            <Image
              className="aspect-square w-[75vw] rounded-[40px] object-cover sm:h-[450px] sm:w-[450px] md:h-[550px] md:w-[550px] lg:h-[650px] lg:w-[650px]"
              src={item.image}
              alt="Location Image"
              width={0}
              height={0}
              sizes="100vw"
            />

            <div className="swiper-caption flex flex-col items-center space-y-1 text-center opacity-0 lg:space-y-3">
              <h1 className="text-2xl font-bold lg:text-5xl lg:font-medium">
                {item.name}
              </h1>
              <p className="flex items-center gap-1 text-sm font-normal lg:text-lg">
                <GrLocation /> {item.city}
              </p>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>

      <div className="swiper-navigation z-20 flex h-8 items-center gap-3 lg:h-12 loc:translate-x-[480px] loc:translate-y-[-275px]">
        <div className="swiper-button-prev slider-arrow h-8 w-8 loc:h-12 loc:w-12">
          <FaChevronLeft
            className="!loc:h-6 !loc:w-6 !h-3 !w-3"
            name="arrow-back-outline"
          />
        </div>
        <div className="swiper-button-next slider-arrow hidden h-8 w-8 loc:flex loc:h-12 loc:w-12">
          <FaChevronRight
            className="!loc:h-6 !loc:w-6 !h-3 !w-3"
            name="arrow-forward-outline"
          />
        </div>
        <div className="swiper-pagination" />
        <div className="swiper-button-next slider-arrow flex h-8 w-8 loc:hidden loc:h-12 loc:w-12">
          <FaChevronRight
            className="!loc:h-6 !loc:w-6 !h-3 !w-3"
            name="arrow-forward-outline"
          />
        </div>
      </div>
    </div>
  )
}

export default OutletLocation
