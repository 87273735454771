import { FaFacebookF, FaInstagram, FaWhatsapp } from 'react-icons/fa6'

import { IOutletLocation, ISocialMediaCompany } from './interfaces'

export const SOCIAL_MEDIA_COMPANY: ISocialMediaCompany[] = [
  {
    id: 1,
    name: 'Facebook',
    icon: FaFacebookF,
    link: 'https://facebook.com/',
  },
  {
    id: 2,
    name: 'WhatsApp',
    icon: FaWhatsapp,
    link: 'https://wa.me/62',
  },
  {
    id: 3,
    name: 'Instagram',
    icon: FaInstagram,
    link: 'https://instagram.com/',
  },
]

export const OUTLET_LOCATION: IOutletLocation[] = [
  {
    id: 1,
    name: 'Befa',
    city: 'Cikarang',
    image: '/images/Befa.JPG',
  },
  {
    id: 2,
    name: 'Delonix',
    city: 'Karawang',
    image: '/images/Delonix.JPG',
  },
  {
    id: 3,
    name: 'Graha',
    city: 'Karawang',
    image: '/images/Graha.JPG',
  },
  {
    id: 4,
    name: 'MM2100',
    city: 'Cikarang',
    image: '/images/MM2100.JPG',
  },
  {
    id: 5,
    name: 'Isoras',
    city: 'Cikarang',
    image: '/images/Isoras.JPG',
  },
  {
    id: 6,
    name: 'Kawana',
    city: 'Cikarang',
    image: '/images/Kawana.jpg',
  },
  {
    id: 7,
    name: 'Maxxis',
    city: 'Cikarang',
    image: '/images/Maxxis.JPG',
  },
  {
    id: 8,
    name: 'PBR',
    city: 'Jakarta',
    image: '/images/PBR.JPG',
  },
  {
    id: 9,
    name: 'Puri',
    city: 'Karawang',
    image: '/images/Puri.JPG',
  },
  {
    id: 10,
    name: 'Sakura',
    city: 'Cikarang',
    image: '/images/Sakura.JPG',
  },
  {
    id: 11,
    name: 'Sancrest',
    city: 'Cikarang',
    image: '/images/Sancrest.JPG',
  },
  {
    id: 12,
    name: 'Senayan',
    city: 'Jakarta',
    image: '/images/Senayan.JPG',
  },
  {
    id: 13,
    name: 'Treepark',
    city: 'Karawang',
    image: '/images/Treepark.JPG',
  },
  {
    id: 14,
    name: 'Trivium',
    city: 'Cikarang',
    image: '/images/Trivium.JPG',
  },
  {
    id: 15,
    name: 'Verde 1',
    city: 'Jakarta',
    image: '/images/Verde 1.JPG',
  },
  {
    id: 16,
    name: 'Verde 2',
    city: 'Jakarta',
    image: '/images/Verde 2.JPG',
  },
]
