import React from 'react'

import { useTranslations } from 'next-intl'
import Image from 'next/image'

const Feature = () => {
  const t = useTranslations()

  return (
    <section className="mx-auto max-w-[1200px] px-6 md:px-24 lg:px-36">
      <header className="mx-auto my-20 max-w-[600px] text-center">
        <h1 className="lggtext-5xl text-4xl font-medium">
          {t('3EasySteps.title')}
        </h1>
        <p className="text-sm font-normal lg:text-lg">
          {t('3EasySteps.content')}
        </p>
      </header>
      <div className="flex flex-col gap-10 lg:flex-row lg:flex-wrap lg:justify-between">
        <div className="flex w-full flex-col gap-6 lg:w-[48%] lg:shrink-0">
          <Image
            width={0}
            height={0}
            className="h-auto w-full rounded-[40px] object-cover"
            src="/images/Choose.jpg"
            alt="Choose"
            style={{ width: '100%', height: 'auto' }}
            sizes="100vw"
          />
          <div className="mx-auto max-w-[440px] text-center">
            <h2 className="text-4xl font-medium">{t('Choose.title')}</h2>
            <p className="text-sm font-normal lg:text-base">
              {t('Choose.content')}
            </p>
          </div>
        </div>
        <div className="flex w-full flex-col gap-6 lg:mt-60 lg:w-[39%] lg:shrink-0">
          <Image
            width={0}
            height={0}
            className="h-auto w-full rounded-[40px] object-cover"
            src="/images/Scan.jpg"
            alt="Scan"
            style={{ width: '100%', height: 'auto' }}
            sizes="100vw"
          />
          <div className="mx-auto max-w-[440px] text-center">
            <h2 className="text-4xl font-medium">{t('Scan.title')}</h2>
            <p className="text-sm font-normal lg:text-base">
              {t('Choose.content')}
            </p>
          </div>
        </div>
        <div className="flex w-full flex-col gap-6 lg:ml-auto lg:mr-[8%] lg:mt-20 lg:max-w-[65%] lg:shrink-0">
          <Image
            width={0}
            height={0}
            className="h-auto w-full rounded-[40px] object-cover"
            src="/images/TakeandGo.jpg"
            alt="Take And Go"
            style={{ width: '100%', height: 'auto' }}
            sizes="100vw"
          />
          <div className="mx-auto max-w-[440px] text-center">
            <h2 className="text-4xl font-medium">{t('TakeAndGo.title')}</h2>
            <p className="text-sm font-normal lg:text-base">
              {t('TakeAndGo.content')}
            </p>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Feature
