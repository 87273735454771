import dynamic from 'next/dynamic'

import Feature from '@/components/Feature'
import Hero from '@/components/Hero'
import OutletLocation from '@/components/OutletLocation'
import Partner from '@/components/Partner'
import PreLoader from '@/components/PreLoader'

const MainLayout = dynamic(() => import('@/components/layout/MainLayout'), {
  ssr: false,
  loading: () => <PreLoader loading={true} />,
})

export default function Home() {
  return (
    <MainLayout>
      <Hero />
      <Feature />
      <OutletLocation />
      <Partner />
    </MainLayout>
  )
}

export async function getStaticProps(context: { locale: string }) {
  return {
    props: {
      // You can get the messages from anywhere you like. The recommended
      // pattern is to put them in JSON files separated by locale and read
      // the desired one based on the `locale` received from Next.js.
      messages: (await import(`../libs/${context.locale}.json`)).default,
    },
  }
}
